<template>
  <base-section
    id="who-am-i"
    class="grey lighten-3"
    space="30"
  >
    <v-container>
      <base-section-heading title="Mission et valeurs de l'entreprise">
        <p class="mission-text">Je crois que chaque contribuable devrait avoir droit à un service personnalisé, professionnel et minutieux lors de la préparation de ses déclarations de revenus.
        <p class="mission-text">Je m’engage donc auprès de mes clients à m’investir pleinement dans leur dossier ainsi qu’à faire preuve de disponibilité, de diligence et de confidentialité.</p>
      </base-section-heading>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'Mission',
  }
</script>

<style scoped>
.mission-text {
  font-size: 20px;
  line-height: 30px;
}
</style>
